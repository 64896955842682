@import 'styles/utils/mixins';
@import 'styles/utils/colors';

.article {
  @include flex(column, initial, flex-start);

  height: 100%;
  box-sizing: border-box;

  .image {
    margin: 0 0 32px;
    width: 100%;
    height: 240px;
    border-radius: 16px;
    object-fit: cover;
    overflow: hidden;
  }

  .articleContent {
    @include flex(column);

    box-sizing: border-box;
    flex-grow: 1;
    width: 100%;
  }

  .date {
    @include fontSize(14px, 150%);

    margin: 0 0 8px;
    color: $gray900;
    font-weight: 400;
  }

  .title {
    @include fontSize(20px, 150%);

    position: relative;
    margin: 0 0 8px;
    padding: 0 24px 0 0;
    flex-grow: 1;
    font-weight: 600;
  }

  .arrowIcon {
    position: absolute;
    top: 0;
    right: 0;
    width: 24px;
    height: 24px;
    transform: rotate(45deg);
  }

  .introduction {
    @include fontSize(16px, 150%);

    margin: 0 0 24px;
    color: rgba($gray800, 0.64);

    @include phone {
      display: none;
    }
  }

  .category {
    @include fontSize(14px, 150%);

    padding: 5.5px 10px;
    width: fit-content;
    color: $indigo700;
    border-radius: 1000px;
    background: $indigo50;
  }
}
